// BlogLayout.js
import React from 'react';
import { Link } from 'react-router-dom';
import './BlogLayout.css'; // Optional: Create a CSS file for layout

function BlogLayout({ children }) {
  return (
    <div className="blog-layout">
      <div className="blog-post">{children}</div>

      {/* Sidebar Section */}
      <div className="sidebar">
        {/* Content for your sidebar, like related articles */}
        <h2>Related Articles</h2>
        <ul>
          <li><Link to="/blog/pocket-medicare-enrollment-guide">Pocket Medicare Enrollment Guide for Seniors</Link></li>
          <li><Link to="/blog/step-by-step-medicare-enrollment-guide">Step-by-Step Medicare Enrollment Guide for Seniors</Link></li>
          <li><Link to="/blog/medicare-enrollment-guide">How to Help Your Clients Navigate Medicare Enrollment</Link></li>
          <li><Link to="/blog/why-partner-with-a-specialized-medicare-enrollment-service">Why Partnering with a Specialized Medicare Enrollment Service is Essential for Your Organization</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default BlogLayout;
