// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import threehandsImage from '../assets/threehands.png';
import UHC from '../assets/UHC.png';
import Cigna from '../assets/Cigna.jpg';
import Wellcare from '../assets/Wellcare.png';
import Devoted from '../assets/Devoted.jpg';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Affordable Medicare Coverage | Policy Masterr</title>
          <meta name="description" content="Policy Masterr helps you find affordable Medicare coverage that fits your needs. Compare Medicare options to make informed decisions today." />
          <link rel="canonical" href="https://www.policymasterr.com" />
        </Helmet>
  
        <div className="main-content">
          <div className="text-content">
            <h1>Find Affordable Medicare Coverage. Feel Better.</h1>
            <p>
              At Policy Masterr, we’re dedicated to helping you find affordable,
              personalized Medicare coverage that fits your life. By comparing quotes from
              multiple Medicare providers, we ensure you make informed decisions on your Medicare needs. Click to compare Medicare Plans today.
            </p>
            <div className="info-message">
              <p>Clicking this button will redirect you to our partner website to compare available Medicare plans.</p>
            </div>
            <div className="button-group">
              {/* Commented out ACA Marketplace button since we are specializing in Medicare */}
              {/* <button onClick={() => window.open("https://www.healthsherpa.com/?_agent_id=emmanuel-agbakpe", "_blank")}>
                ACA Marketplace
              </button> */}
              <button onClick={() => window.open("https://planenroll.com/?purl=Emmanuel-Agbakpe", "_blank")} title="Click to compare Medicare plans">
                Medicare
              </button>
            </div>
          </div>
        </div>
  
        {/* Separate section for the image */}
        {<section className="threehandsimage-container">
          <div className="image-container">
            <img src={threehandsImage} alt="Happy family finding health coverage through Policy Masterr" />
          </div>
        </section>}

        {/* Insurance company logos section */}
        {<section className="insurance-logos">
          <h2>Trusted Medicare Providers</h2>
          <div className="logos">
            <img src={UHC} alt="United Healthcare" className="logo" />
            <img src={Cigna} alt="Cigna" className="logo" />
            <img src={Wellcare} alt="Wellcare" className="logo" />
            <img src={Devoted} alt="Devoted" className="logo" />
          </div>
        </section>}

        {/* Medicare Resources for organizations section */}
        {<section className="blog">
          <h2>Medicare Resources for Organizations</h2>
          <ul>
            <li><Link to="/blog/pocket-medicare-enrollment-guide">Pocket Medicare Enrollment Guide for Seniors</Link></li>
            <li><Link to="/blog/step-by-step-medicare-enrollment-guide">Step-by-Step Medicare Enrollment Guide for Seniors</Link></li>
            <li><Link to="/blog/medicare-enrollment-guide">How to Help Your Clients Navigate Medicare Enrollment</Link></li>
            <li><Link to="/blog/why-partner-with-a-specialized-medicare-enrollment-service">Why Partnering with a Specialized Medicare Enrollment Service is Essential for Your Organization</Link></li>
          </ul>

          <div className="cta-partners">
            <h3>Ready to Partner?</h3>
            <p>Let us help simplify Medicare enrollment for your clients. Get in touch with us to explore partnership opportunities.</p>
            <button onClick={() => window.open("/about", "_blank")}>Contact Us to Partner</button>
          </div>

        </section>}

      </>
    );
}

export default Home;  