// Layout.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Layout.css';

function Layout({ children }) {
  return (
    <div className="App">
      {/* Helmet for structured data */}
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Policy Masterr",
              "url": "https://www.policymasterr.com",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": [
                  "+1 (888) 420-7408",
                  "(346) 253-2556"
                ],
                "contactType": "Customer Service",
                "areaServed": "US",
                "availableLanguage": "English"
              }
            }
          `}
        </script>
    </Helmet>

      {/* Header with Navbar */}
      <header className="App-header">
        <div className="header-content">
          <h2 className="logo">
            <Link to="/" className="logo-link">
              <span className="policy">Policy</span><span className="masterr">Masterr</span>
            </Link>
          </h2>
          <p className="top-bar-content">
          +1 (888) 420-7408 | (346) 253-2556 | Mon - Fri, 9 AM - 5 PM ET
          </p>
        </div>
        <nav className="navbar">
          <ul className="navbar-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/blogs">Blogs</Link></li>
            {/* <li><Link to="/blog/medicare-enrollment-guide">Medicare Enrollment Guide</Link></li> */}
            <li><span>Currently Serving Texas</span></li>
          </ul>
        </nav>
      </header>

      {/* Main Content */}
      <main className="App-main">
        {children}
      </main>

      {/* Footer */}
      <footer className="app-footer">
        <h3>Get in Touch to Discuss Your Medicare Options</h3>
        <p>Are you 65 or turning 65 soon? Set up an appointment to explore your Medicare options and find a plan that fits your needs.</p>
        <p>Currently serving Texas residents.</p>

        {/* Alternative Contact via Phone */}
        <div className="footer-contact">
          <p style={{ fontSize: '24px'}}>Call today!</p>
          <p style={{ fontSize: '24px'}}>+1 (888) 420-7408 | (346) 253-2556 | Mon - Fri, 9 AM - 5 PM ET</p>
        </div>
        <p>© 2024 Policy Masterr | All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default Layout;
