//App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/About';
import Layout from './components/Layout';
import BlogLayout from './components/BlogLayout';
import Home from './pages/Home'; // Import Home component
import Blogs from './pages/Blogs';
import MedicareEnrollmentGuide from './pages/blog/MedicareEnrollmentGuide';
import WhyPartnerWithASpecializedMedicareEnrollmentService from './pages/blog/WhyPartnerWithASpecializedMedicareEnrollmentService';
import StepByStepMedicareEnrollmentGuide from './pages/blog/StepByStepMedicareEnrollmentGuide';
import PocketMedicareEnrollmentGuide from './pages/blog/PocketMedicareEnrollmentGuide';
import './App.css';

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/blogs" element={<Layout><Blogs /></Layout>} />
        
        {/* Blog-specific routes wrapped with BlogLayout */}
        <Route path="/blog/medicare-enrollment-guide" element={<Layout><BlogLayout><MedicareEnrollmentGuide /></BlogLayout></Layout>} />
        <Route path="/blog/why-partner-with-a-specialized-medicare-enrollment-service" element={<Layout><BlogLayout><WhyPartnerWithASpecializedMedicareEnrollmentService /></BlogLayout></Layout>} />
        <Route path="/blog/step-by-step-medicare-enrollment-guide" element={<Layout><BlogLayout><StepByStepMedicareEnrollmentGuide /></BlogLayout></Layout>} />
        <Route path="/blog/pocket-medicare-enrollment-guide" element={<Layout><BlogLayout><PocketMedicareEnrollmentGuide /></BlogLayout></Layout>} />

        <Route path="/Policy Master" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
