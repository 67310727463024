// Blogs.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Link } from 'react-router-dom';
import './Blogs.css'; // Custom styles for the Blogs page

function Blogs() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleReadMoreClick = (url) => {
    navigate(url); // Navigate to the specified URL
  };

  return (
    <>
      <Helmet>
        <title>Blogs | Policy Masterr</title>
        <meta name="description" content="Explore our collection of informative blogs about Medicare and health insurance." />
        <link rel="canonical" href="https://www.policymasterr.com/blogs" />
      </Helmet>

      <div className="blogs-container">
        <h1>Our Blogs</h1>
        <p>Stay informed with the latest blog posts about Medicare, insurance, and more!</p>
        <div className="blog-grid">
          <div className="blog-card">
            <h2><Link to="/blog/medicare-enrollment-guide">How to Help Your Clients Navigate Medicare Enrollment</Link></h2>
            <p>Navigating Medicare enrollment can be overwhelming for many seniors, with numerous options, deadlines, and eligibility requirements to consider. As a healthcare provider, senior service organization, or nonprofit dedicated to serving the aging population,</p>
            {/* Updated button */}
            <button onClick={() => handleReadMoreClick("/blog/medicare-enrollment-guide")}>Read More</button>
          </div>
          <div className="blog-card">
            <h2><Link to="/blog/why-partner-with-a-specialized-medicare-enrollment-service">Why Partnering with a Specialized Medicare Enrollment Service is Essential for Your Organization</Link></h2>
            <p>As the healthcare landscape continues to evolve, organizations are increasingly tasked with offering comprehensive healthcare options for their employees and clients. One critical area that often requires specialized attention is Medicare enrollment.</p>
            <button onClick={() => handleReadMoreClick("/blog/why-partner-with-a-specialized-medicare-enrollment-service")}>Read More</button>
          </div>
          <div className="blog-card">
            <h2><Link to="/blog/step-by-step-medicare-enrollment-guide">Step-by-Step Medicare Enrollment Guide for Seniors</Link></h2>
            <p>As you approach your 65th birthday, it’s important to understand your eligibility and the steps to enroll in Medicare. Medicare is a government program designed to help seniors access the healthcare services they need. Our guide breaks down the process to help you navigate enrollment and ensure you have the right coverage for your needs.</p>
            <button onClick={() => handleReadMoreClick("/blog/step-by-step-medicare-enrollment-guide")}>Read More</button>
          </div>
          <div className="blog-card">
            <h2><Link to="/blog/pocket-medicare-enrollment-guide">Pocket Medicare Enrollment Guide for Seniors</Link></h2>
            <p>Medicare is essential for seniors' healthcare. Here’s a simplified guide to help you understand the key steps for enrollment.</p>
            <button onClick={() => handleReadMoreClick("/blog/pocket-medicare-enrollment-guide")}>Read More</button>
          </div>
          {/* Add more blog entries here */}
        </div>
      </div>
    </>
  );
}

export default Blogs;
